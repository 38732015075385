import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { bindActionCreators, compose } from 'redux';
import { withSnackbar } from 'material-ui-snackbar-redux';
import { connect } from 'react-redux';
import { downloadPromptsFileRequested, uploadPromptsFileRequested } from '../../stores/promptsFile/actions';
import { getPromptsFileName } from '../../selectors/promptsFile';
import { StyledFileDownloadButton, StyledSingleFileDropZone } from '../FormComponents';
import MessagePaper from '../MessagePaper';
import { getUrl, withRouter } from '../../utils';

const SaveButton = ({ onClick, hidden, disabled }) => {
    return (
        <Button
            color="primary"
            variant="contained"
            onClick={onClick}
            disabled={disabled}
            style={{ display: hidden ? 'none' : 'inline-flex' }}
        >
            {disabled ? <CircularProgress /> : 'Save'}
        </Button>
    );
};
SaveButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    hidden: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
};

class UploadPromptsFile extends React.Component {
    static propTypes = {
        uploadPromptsFileRequested: PropTypes.func.isRequired,
        downloadPromptsFileRequested: PropTypes.func.isRequired,
        uploading: PropTypes.bool.isRequired,
        snackbar: PropTypes.shape({
            show: PropTypes.func.isRequired,
        }).isRequired,
        filename: PropTypes.string.isRequired,
        router: PropTypes.object.isRequired,
        uploadSucceeded: PropTypes.bool.isRequired,
    };

    state = {
        accepted: [],
        hidden: true,
    };

    onDrop = (accepted) => {
        this.setState({ accepted });
        this.setState({ hidden: false });
    };

    onSave = () => {
        const { accepted } = this.state;
        if (accepted && accepted.length === 1) {
            const { uploadPromptsFileRequested } = this.props;
            uploadPromptsFileRequested(accepted[0]);
            this.setState({ accepted: [] });
        } else {
            const { snackbar } = this.props;
            snackbar.show('There is no uploaded file to be saved, please try again.');
        }
    };

    componentDidMount () {
        this.props.downloadPromptsFileRequested();
    }

    render () {
        const { uploading, filename, uploadSucceeded, router } = this.props;
        const { hidden } = this.state;
        return (
            <Grid container direction="column">
                <Grid item>
                    <Typography variant="h1" gutterBottom>
                        Upload Prompts File
                    </Typography>
                </Grid>
                <Grid container alignItems="flex-end">
                    <Grid item>
                        <Typography gutterBottom>
                            Current Prompts File:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <StyledFileDownloadButton url={getUrl(window, router)} filename={filename} />
                    </Grid>
                </Grid>
                <Grid item>
                    <StyledSingleFileDropZone
                        label="Prompts"
                        onDrop={(accepted) => this.onDrop(accepted)}
                        accepted={this.state.accepted}
                    />
                </Grid>
                <Grid item>
                    {uploadSucceeded &&
                    <MessagePaper type="success">
                        Successfully uploaded the new prompts file!
                    </MessagePaper>}
                </Grid>
                <Grid item component={Divider} />
                <Grid item>
                    <SaveButton onClick={this.onSave} hidden={hidden} disabled={uploading} />
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        uploading: state.promptsFile.uploading,
        upload: state.promptsFile.upload,
        filename: getPromptsFileName(state),
        uploadSucceeded: state.promptsFile.uploadSucceeded,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        ...(bindActionCreators({ uploadPromptsFileRequested, downloadPromptsFileRequested }, dispatch)),
    };
};

export default compose(
    withSnackbar(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(UploadPromptsFile);
