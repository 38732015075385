import { createTheme } from '@mui/material/styles';
import { createColStylesGeneratorForTheme } from './utils/styles';

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        primary: { main: '#6089BD' },
        secondary: { main: '#D95E16' },
    },
    typography: {
        fontFamily: 'filson-soft, sans-serif',
        useNextVariants: true,
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                subtitle1: {
                    color: 'white',
                    marginLeft: 15,
                    fontSize: 16,
                    fontFamily: 'filson-soft, sans-serif',
                },
                subtitle2: {
                    marginLeft: 15,
                    color: 'black',
                    fontSize: 16,
                    fontFamily: 'filson-soft, sans-serif',
                },
                h3: { color: 'white', fontSize: 24, marginTop: 20 },
                h2: { color: 'white' },
                h1: { color: '#002244', fontSize: 36, fontWeight: 'normal' },
                h4: {
                    color: '#002244',
                    fontSize: 24,
                    marginLeft: 15,
                    fontWeight: 'bold',
                    marginTop: 25,
                },
                h5: {
                    color: '#002244',
                    fontSize: 24,
                    fontFamily: 'filson-soft, sans-serif',
                },
                colorError: {
                    color: '#F33E36',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    color: '#6089BD',
                    fontSize: 14,
                    height: 'auto',
                    paddingTop: 6,
                    paddingBottom: 6,
                },
                outlined: {
                    backgroundColor: 'white',
                    borderColor: '#6089BD',
                },
                clickable: {
                    border: '1px solid rgb(108, 146, 194)',
                },
                deleteIcon: {
                    fill: '#F33E36',
                    height: '0.8em',
                    width: '0.8em',
                },
                outlinedPrimary: {
                    color: 'white',
                    backgroundColor: '#6089BD',
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    padding: 0,
                    display: 'flex',
                },
                horizontal: {
                    backgroundColor: '#DFDFDF',
                    height: 71,
                    justifyContent: 'space-evenly',
                    width: '100%',
                    display: 'flex',
                },
                // Styles for the iconContainer of the step label
                iconContainer: {
                    paddingRight: 0,
                    paddingLeft: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    color: '#303233',
                    backgroundColor: '#DFDFDF',
                },
                '&.Mui-active': {
                    backgroundColor: '#002244',
                },
                label: {
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    color: '#303233',
                    fontSize: 16,
                    paddingTop: 24,
                    paddingBottom: 24,
                    '&$active': {
                        backgroundColor: '#002244',
                        color: 'white',
                    },
                },
                labelContainer: {
                    width: 352,
                },
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                root: {
                    flex: 0,
                },
                lineHorizontal: {
                    borderColor: '#DFDFDF',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    backgroundColor: '#DFDFDF',
                    color: '#57444E',
                    textAlign: 'left',
                    fontSize: '0.8rem',
                    padding: '10px 12px',
                },
                selectMenu: {
                    fontSize: '1.1em',
                    lineHeight: '1.2em',
                    padding: '10px 12px',
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    alignSelf: 'center',
                    width: '95%',
                },
            },
        },
        MuiStep: {
            styleOverrides: {
                root: {
                    flex: 1,
                },
                horizontal: {
                    paddingLeft: 0,
                    paddingRight: 0,
                    display: 'flex',
                    alignItems: 'stretch',
                    flexDirection: 'row',

                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    borderRadius: 3,
                    overflow: 'hidden',
                },
                input: {
                    backgroundColor: '#DFDFDF',
                    padding: '10px 12px',
                },
                '&.Mui-disabled': {
                    color: '#57444E',
                    opacity: 0.5,
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                underline: {
                    backgroundColor: '#DFDFDF',
                },
                input: {
                    padding: 0,
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                filled: {
                    backgroundColor: '#DFDFDF',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                filled: {
                    top: '10%',
                    left: '5%',
                    transform: 'translate(0px, 15px) scale(1)',
                },
                shrink: {
                    color: '#57444E',
                    marginLeft: -14,
                    fontWeight: 'bold',
                    transform: 'translate(0px, 4px) scale(0.75)',
                },
                outlined: {
                    marginLeft: -14,
                    marginTop: -7,
                },
                animated: {
                    marginLeft: 0,
                },
                formControl: {
                    transform: 'translate(0, 12px) scale(1)',
                },
                root: {
                    position: 'relative',
                    left: 14,
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    color: 'white',
                    paddingTop: 16,
                    paddingBottom: 16,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'white',
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: 'rgba(0, 0, 0, 0.50) !important',
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: 'rgba(0, 0, 0, 0.50) !important',
                    },
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                bar: {
                    backgroundColor: '#DFDFDF',
                    opacity: 0.5,
                    borderRadius: 15,
                    width: 42,
                    height: 25,
                    marginTop: -13,
                    marginLeft: -21,
                    border: 'solid 1px',
                },
                '&.Mui-checked': {
                    transform: 'translateX(17px)',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#DFDFDF',
                    padding: 0,
                    borderRadius: 0,
                    backgroundColor: '#DFDFDF',
                    border: 'none',
                    height: 16,
                    width: 16,
                    '&.Mui-checked': {
                        color: '#003263',
                        padding: 0,
                    },
                },
                indeterminate: {
                    backgroundColor: '#002244',
                },
            },
        },
        MUIDataTableSelectCell: {
            styleOverrides: {
                root: {
                    width: 40,
                    maxWidth: 40,
                },
                checkboxRoot: {
                    color: '#DFDFDF',
                    padding: 0,
                    borderRadius: 0,
                    backgroundColor: '#DFDFDF',
                    height: 16,
                    width: 16,
                    marginBottom: 5,
                    '&$checked': {
                        color: '#DFDFDF',
                        padding: 0,
                        backgroundColor: '#003263',
                    },
                    '&$disabled': {
                        display: 'none',
                    },
                },
                fixedLeft: {
                    paddingRight: 25,
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                gutters: {
                    paddingLeft: '0 !important',
                    paddingRight: '0 !important',
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    borderBottom: '5px solid lightgrey',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                head: {
                    height: 40,
                },
                root: {
                    height: 48,
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    '&:before': {
                        borderBottomWidth: 0,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 4,
                    paddingBottom: 4,
                },
                head: {
                    color: '#002244',
                    fontWeight: 'bold',
                    fontSize: 10,
                    verticalAlign: 'bottom',
                    paddingBottom: 10,
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                select: {
                    width: 24,
                    fontSize: 12,
                    padding: 1,
                    textIndent: 2,
                    marginTop: 1,
                },
                selectIcon: {
                    top: '-3px',
                },
                toolbar: {
                    display: 'flex',
                    alignItems: 'center',
                    width: 'unset',
                },
                caption: {
                    marginTop: 15,
                },
                actions: {
                    marginTop: 15,
                },
            },
        },
        MUIDataTablePagination: {
            styleOverrides: {
                root: {
                    '&:last-child': {
                        paddingRight: '0',
                    },
                },
                selectRoot: {
                    marginTop: 15,
                },
            },
        },
        MUIDataTableFilter: {
            styleOverrides: {
                selectRoot: {
                    flexWrap: 'nowrap',
                },
                root: {
                    width: '100%',
                    margin: 0,
                    padding: '16px 0 16px 24px',
                    boxSizing: 'border-box',
                    backgroundColor: 'transparent',
                },
            },
        },
        MUIDataTableSearch: {
            styleOverrides: {
                main: {
                    alignItems: 'center',
                },
                searchIcon: {
                    marginTop: 0,
                },
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    textAlign: 'center',
                    marginRight: 10,
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    textAlign: 'center',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    margin: 0,
                    justifyContent: 'center',
                },
            },
        },
        MUIDataTableHeadCell: {
            styleOverrides: {
                root: {
                    whiteSpace: 'nowrap',
                    textTransform: 'uppercase',
                },
                toolButton: {
                    whiteSpace: 'nowrap',
                    fontSize: '10px',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    padding: 0,
                    letterSpacing: 0,
                },
                data: {
                    whiteSpace: 'nowrap',
                },
            },
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    backgroundColor: 'red',
                },
            },
        },
        DataTable: {
            styleOverrides: {
                input: {
                    'label + &': {
                        marginTop: 0,
                    },
                },
                filter: {
                    width: 'auto',
                    minWidth: 150,
                    marginLeft: 10,
                    height: 48,
                    justifyContent: 'center',
                },
                actions: {
                    width: 150,
                    height: 48,
                    justifyContent: 'center',
                    marginLeft: 10,
                },
                searchToggleButton: {
                    marginLeft: 10,
                },
                searchControl: {
                    width: 'auto',
                },
                tableEmptyCell: {
                    margin: 0,
                    padding: 0,
                    height: '100%',
                    '&:last-child': {
                        paddingRight: 0,
                    },
                },
                tableLoadingCell: {
                    margin: 0,
                    padding: 0,
                    height: '100%',
                    '&:last-child': {
                        paddingRight: 0,
                    },
                },
            },
        },

        DataTableColumn: {
            styleOverrides: {
                sortArrow: {
                    width: 'auto',
                    height: '1em',
                    marginTop: '-0.5em',
                    marginBottom: '-0.5em',
                },
            },
        },
    },
    softpro: {
        tableColumnWidths: {
            lastUpdatedCol: 112,
            typeCol: 100,
            adminTypeCol: 90,
            stateCol: 120,
            adminStateCol: 140,
            packageNameCol: 'auto',
            relNotesCol: 70,
            notificationsCol: 120,
            addCol: 140,
            downloadCol: 120,
            removeCol: 25,
            downloadedAtCol: 200,
            emailCol: 250,
            orgCol: 225,
            serialCol: 120,
            lastDownloadedCol: 375,
            countCol: 135,
            viewCol: 110,
            releaseNotesCol: 5,
            downloadNotesCol: 5,
            zipContentCol: 5,
            editCol: 110,
            editionCol: 120,
            adminEditionCol: 90,
            underwriterNameCol: 150,
            emailAutoCol: 'auto',
            notificationDateCol: 150,
            stretchCol: 'auto',
        },
    },
});

const generateColStyles = createColStylesGeneratorForTheme(theme);

theme.softpro.tableColumns = Object.keys(
    theme.softpro.tableColumnWidths,
).reduce(
    (tableColumns, tableColumnKey) => ({
        ...tableColumns,
        [`${tableColumnKey}`]: generateColStyles(
            theme.softpro.tableColumnWidths[tableColumnKey],
        ),
    }),
    {},
);

theme.softpro.tableColumns.removeCol = {
    ...theme.softpro.tableColumns.removeCol,
    textAlign: 'center',
    padding: 'auto',
};

theme.softpro.tableColumns.countCol = {
    ...theme.softpro.tableColumns.countCol,
    textAlign: 'center',
    fontWeight: 'bold',
};

theme.softpro.tableColumns.packageNameCol = {
    ...theme.softpro.tableColumns.packageNameCol,
};

theme.softpro.tableColumns.underwriterNameCol = {
    ...theme.softpro.tableColumns.underwriterNameCol,
};

theme.softpro.tableColumns.notificationDateCol = {
    ...theme.softpro.tableColumns.notificationDateCol,
    textAlign: 'center',
    fontWeight: 'bold',
};

theme.softpro.tableColumns.addCol = {
    paddingRight: 2,
    textAlign: 'center',
};

theme.softpro.tableColumns.releaseNotesCol = {
    ...theme.softpro.tableColumns.releaseNotesCol,
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
};

theme.softpro.tableColumns.downloadCol = {
    ...theme.softpro.tableColumns.downloadCol,
    textAlign: 'center',
};

theme.softpro.tableColumns.downloadNotesCol = {
    ...theme.softpro.tableColumns.downloadNotesCol,
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
};

theme.softpro.tableColumns.zipContentCol = {
    ...theme.softpro.tableColumns.zipContentCol,
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
};

const dataTableCheckboxStyles = {
    ...generateColStyles(24),
    textAlign: 'center',
    paddingLeft: 4,
    paddingRight: 6,
};

theme.components.DataTable.styleOverrides.checkboxBodyCell = {
    ...dataTableCheckboxStyles,
};
theme.components.DataTable.styleOverrides.checkboxHeadCell = {
    ...dataTableCheckboxStyles,
};

theme.softpro.safeBox = {
    marginTop: 150,
    marginLeft: 350,
    marginRight: 50,
    marginBottom: 50,
    width: 'auto',
    [theme.breakpoints.up('xl')]: {
        marginLeft: 385,
        marginRight: 85,
    },
    [theme.breakpoints.down('lg')]: {
        marginLeft: 250,
    },
};

theme.components.MuiPaper = {
    styleOverrides: {
        root: {
            backgroundImage: 'unset',
        },
        elevation2: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            [theme.breakpoints.down('lg')]: {
                width: '95%',
            },
        },
        elevation1: {
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            justifyContent: 'stretch',
            border: '15px solid white',
        },
        elevation5: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
        },
        elevation4: {
            boxShadow: 'none',
        },
    },
};

theme.components.MuiStepLabel = {
    styleOverrides: {
        root: {
            color: '#303233',
            backgroundColor: '#DFDFDF',
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'row',
            flex: 1,
            position: 'relative',
        },
        active: {
            backgroundColor: '#002244',
        },
        label: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            color: '#303233',
            fontSize: 16,
            paddingTop: 24,
            paddingRight: 10,
            paddingBottom: 24,
            alignItems: 'center',
            flex: 1,
            '&$active': {
                backgroundColor: '#002244',
                color: 'white',
            },
            [theme.breakpoints.down('lg')]: {
                paddingLeft: 10,
            },
        },
        labelContainer: {
            width: 'auto',
            flex: 1,
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'row',
        },
        iconContainer: {
            [theme.breakpoints.down('lg')]: {
                position: 'relative',
                top: 0,
                left: 0,
                paddingLeft: '0.5rem',
            },
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            left: '2rem',
            top: 'calc(50% - 0.75rem)',
        },
    },
};

theme.components.MuiDrawer = {
    styleOverrides: {
        paperAnchorDockedLeft: {
            backgroundColor: '#002244',
            border: '1px solid #707070',
            width: 300,
            flexShrink: 0,
            marginTop: 105,
            height: 'calc(100% - 105px)',
            [theme.breakpoints.down('lg')]: {
                width: 200,
            },
        },
    },
};

theme.components.MUIDataTableToolbar = {
    styleOverrides: {
        actions: {
            display: 'flex',
            flexBasis: 'auto',
            [theme.breakpoints.down('lg')]: {
                alignSelf: 'flex-end',
            },
        },
        root: {
            minHeight: 0,
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: 0,
            marginTop: 0,
            [theme.breakpoints.down('lg')]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginBottom: 5,
            },
        },
    },
};

theme.components.MUIDataTableToolbarSelect = {
    styleOverrides: {
        root: {
            ...theme.components.MUIDataTableToolbar.styleOverrides.root,

            flex: 'inherit',
            justifyContent: 'space-between',
            backgroundColor: 'transparent',
            borderRadius: 0,
            boxShadow: 'none',
        },
        title: {
            display: 'none',
        },
    },
};

theme.components.MuiButton = {
    styleOverrides: {
        root: {
            borderRadius: 50,
            fontSize: 16,
            letterSpacing: 4,
            fontWeight: 'bold',
            marginTop: 25,
            padding: '10px 20px',
            lineHeight: 1.5,
        },
        label: {
            fontFamily: 'filson-soft, sans-serif',
        },
        containedPrimary: {
            color: '#fff',
        },
        sizeLarge: {
            fontSize: 12,
            width: 298,
            height: 35,
            letterSpacing: 2,
        },
        flatPrimary: {
            letterSpacing: 1,
            fontSize: 16,
            height: 16,
            marginTop: 0,
            width: '20%',
        },
        sizeSmall: {
            marginTop: 0,
            padding: '8px 10px',
        },
        hidden: {
            display: 'none',
        },
    },
};

theme.components.MUIDataTableBodyCell = {
    styleOverrides: {
        responsiveStacked: {
            [theme.breakpoints.down('lg')]: {
                width: '58%',
            },
        },
    },
};

theme.components.MuiStepper = {
    styleOverrides: {
        root: {
            padding: 0,
            display: 'flex',
            flexWrap: 'wrap',
        },
        horizontal: {
            backgroundColor: '#DFDFDF',
            height: 71,
            alignItems: 'stretch',
            justifyContent: 'space-evenly',
            display: 'flex',
            [theme.breakpoints.down('lg')]: {
                height: 'auto',
            },
            '.Mui-active': { backgroundColor: '#002244', transition: '0.25s' },
        },

    },
};

theme.components.MuiSvgIcon = {
    styleOverrides: {
        colorDisabled: {
            width: 42,
            height: 42,
            [theme.breakpoints.down('lg')]: {
                width: 32,
                height: 32,
            },
        },
        colorPrimary: {
            width: 42,
            height: 42,
            [theme.breakpoints.down('lg')]: {
                width: 32,
                height: 32,
            },
        },
        colorSecondary: {
            width: 42,
            height: 42,
            [theme.breakpoints.down('lg')]: {
                width: 32,
                height: 32,
            },
        },
    },
};

theme.components.MuiTabs = {
    styleOverrides: {
        scroller: {
            backgroundColor: '#fff',
            color: '#002244',
        },
        indicator: {
            display: 'none',
        },
    },
};

theme.components.MuiTab = {
    styleOverrides: {
        root: {
            fontWeight: 'normal',
            borderBottom: '1px solid #6089BD',
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            textTransform: 'none',
            fontSize: '0.9em',
            minWidth: 180,
        },
        selected: {
            borderLeft: '1px solid #6089BD',
            borderRight: '1px solid #6089BD',
            borderTop: '1px solid #6089BD',
            borderBottom: 'unset',
            fontWeight: 'bold',
        },
    },
};

theme.components.DataTable = {
    styleOverrides: {
        ...theme.components.DataTable.styleOverrides,
        top: {
            justifyContent: 'flex-end',
            [theme.breakpoints.down('lg')]: {
                flexWrap: 'wrap',
                justifyContent: 'center',
                paddingBottom: '1em',
            },
        },
    },
};

theme.components.DataTableColumn = {
    styleOverrides: {
        ...theme.components.DataTableColumn.styleOverrides,
        headCell: {
            whiteSpace: 'nowrap',
            textTransform: 'uppercase',
            [theme.breakpoints.down('lg')]: {
                whiteSpace: 'normal',
                fontSize: 9,
            },
        },
    },
};
